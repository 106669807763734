import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Spinner = ({ blockKeyboard }) => {
  useEffect(() => {
    const preventKeyboardEvents = e => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (blockKeyboard) {
      document.addEventListener('keydown', preventKeyboardEvents, true);
      document.addEventListener('keyup', preventKeyboardEvents, true);
    }

    return () => {
      if (blockKeyboard) {
        document.removeEventListener('keydown', preventKeyboardEvents, true);
        document.removeEventListener('keyup', preventKeyboardEvents, true);
      }
    };
  }, [blockKeyboard]);

  return (
    <div
      className="tv-loading__wrapper"
      role="alert"
      aria-live="assertive"
      aria-busy="true"
    >
      <div className="tv-loading__content">
        <div className="tv-loading__spinner" />
      </div>
    </div>
  );
};

Spinner.propTypes = {
  blockKeyboard: PropTypes.bool,
};

Spinner.defaultProps = {
  blockKeyboard: true,
};

export default Spinner;
